import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Select from "react-select";
import { styled as styled_mui } from '@mui/material/styles';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import quickLinkIcon from "../../../../assets/images/quick-link.svg";
import Divider from '@mui/material/Divider';

const BootstrapDialog = styled_mui(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function SolutionModal({
  solutionModal, 
  closeSolutionModal,
  isIndepthKnowledge,
  section, 
}) {
  const [open, setOpen] = React.useState(false);
  const [solutionList,setSolutionList] = React.useState([]);
  const mainData = solutionModal?.data;
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const structureSolution = ()=>{
      const temp=[];
      for(let key in mainData?.solution)
      {
        temp.push(mainData?.solution[key])
      }
      setSolutionList(temp);
  }

  React.useEffect(()=>{
    structureSolution();
  },[mainData])

  return (
    <CustomContainer style={{ display: "inline-block", alignSelf:"center"}}>
      <BootstrapDialog
        open={solutionModal?.status}
        onClose={closeSolutionModal}
        maxWidth="md"
        fullWidth
      >
       <DialogContent>
          <CustomTitle>
            <p className="font-style font-16 fg-black bold-500 question-multiline d-flex">
              <div>Q. </div>
              <div className='ql-editor quill-box' dangerouslySetInnerHTML={{__html: mainData?.question?.name ? mainData?.question?.name 
              : mainData?.question?.statement ? mainData?.question?.statement
              : ""}}></div>
            </p>

            {/* This check ensures if name is present then statement will be presented in second line otherwise first line itself. */}
            <p className="font-style font-13 fg-black bold-400 ql-editor quill-box"
             dangerouslySetInnerHTML={{__html:
                  mainData?.question?.name && mainData?.question?.statement && mainData?.question?.statement?.length>0?mainData?.question?.statement:""
             }}>
            </p>
            <p className="font-style font-13  pml-25">
              <span className="font-style bold-400 fg-gray5">{section} </span>
              
            {mainData?.difficulty_level &&  
            <>&#8226;<span
                className={
                  mainData?.difficulty_level?.toLowerCase() === "easy"
                    ? "font-style bold-600 fg-green2"
                    : mainData?.difficulty_level?.toLowerCase() === "medium"
                    ? "font-style bold-600 fg-gold"
                    : "font-style bold-600 fg-red"
                }
              >
                {" "}
                {mainData?.difficulty_level?.toUpperCase()}{" "}
              </span>
              </>}

              {mainData?.avg_time_taken ? (
                <>&#8226; <span className="font-style bold-400 fg-gray4">
                  {mainData?.avg_time_taken} mins
                </span> </>
              ) : (
                ""
              )}

              {mainData?.question?.link && (
                <span >&#8226;
                <a
                  href={mainData?.question?.link}
                  target="_blank"
                  className='font-style question-link'
                > Question Link <img src={quickLinkIcon}/>
                </a></span>
              )}
            </p>
          </CustomTitle>
          <Divider />
          <CustomContentBox>
            {  mainData?.things_to_check && mainData?.things_to_check?.length > 0 ?  <Box sx={{padding: "5px 10px",marginTop :'5px', borderRadius:'11px'}} className="bg-lightblue2">
                          <Typography className='font-style font-14 bold-600' sx={{color:'#3B4B77'}}>Things to Check</Typography>
                          <ul className='font-13 bold-400' style={{marginLeft:'0px'}}>
                          {   mainData?.things_to_check?.map((data)=>{
                                return data ? <li>{data}</li> : <></>
                            })
                          }
                          </ul> </Box> : ""
            }
            {
              solutionList && solutionList?.length>0?solutionList?.map((item,index)=>{
                return (
                  <Box>
                    <Typography className='font-style font-14 bold-600 fg-black' marginTop="10px">Solution {index + 1} Approach:</Typography>
                    <Typography marginTop="5px" className="font-style font-14 bold-400 fg-black">
                      {
                        section==='Theoretical Question' && item?.approach?<Typography  marginTop="5px"
                          style={{ padding: "15px" }}
                          className="font-style font-14 bold-400 fg-black bg-lightblue2">
                        
                           {item?.approach}
                       
                      </Typography>
                       :item?.approach?item?.approach:"NA"

                      }</Typography>
                    {
                      item?.question?.statement?<Typography marginTop="5px" className='font-style'>{item?.question?.statement}</Typography>:""
                    }
                   
                    {item?.code ? (
                      <Box>
                        <Typography className='font-style font-14 bold-600 fg-black' marginTop="10px">Solution {index + 1} Code:</Typography>
                        <Typography
                          marginTop="5px"
                          style={{ padding: "15px" }}
                          className="bg-lightblue2"
                        >
                          <pre>
                            <code className="font-style font-14 bold-400 fg-black">{item?.code}</code>
                          </pre>
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )}
                    {
                      item?.time_complexity?<Box>
                        <Typography className='font-style font-14 bold-600 fg-black' marginTop="10px">
                        Solution {index+1} Complexity:
                        </Typography>
                        <Typography marginTop="5px" className="font-style font-14 bold-400 fg-black">
                          {
                            item?.time_complexity
                          }
                        </Typography>
                      </Box>:""
                    }
                    <br/>
                  </Box>
                );
              })
              :""
             
            }
          </CustomContentBox>
        </DialogContent>
      </BootstrapDialog>
    </CustomContainer>
  );
}


const CustomContainer = styled.div`
    .guide_preview_view_button {
        text-decoration: underline;
        cursor: pointer;
    }
    .font-11 {
        font-size: 11px;
    }
    .fg-blue2 {
        color: #123FBC;
    }
    .bold-600 {
        font-weight: 600;
    }
`;

const CustomTitle = styled.div`

.question-multiline{
  text-indent: -30px;
    margin-left: 30px;
    white-space: break-spaces;
    line-height:1.2;
    display: flex;
    gap: 20px;
}
    .quill-box {
      width: 90%;
      line-break: auto;
      word-break: break-word;
      display: block;
    }
    .quill-box img{
      max-height: 400px;
      max-width: 60%;  
      margin: 6px;  
      display: block;
    }
    .quill-box p{
      line-break: auto;
      word-break: break-word;
      margin-bottom: 0.2rem;
    }
    .d-flex{
      display: flex;
      align-items: flex-start;
    }
    .font-style{
      font-family: 'Poppins';
      letter-spacing: 0.005em;
      white-space: break-spaces;
    }
    .question-link
    {
      font-weight: 600;
      font-size: 13px;
      color: #5B87FF;
      text-decoration: underline;
    }
     .font-18 {
        font-size: 18px;
    }
    .font-16 {
        font-size: 16px;
    }
    .font-13 {
        font-size: 13px;
    }
    .nmt-20 {
    margin-top: -20px;
  }
  .bold-600 {
        font-weight: 600;
    }
    .bold-500 {
        font-weight: 500;
    }
    .bold-400{
      font-weight: 400;
    }
    .fg-black {
        color: #3D3D3D;
    }

    .fg-gray5 {
        color: #ADADAD;
    }
    .fg-green2 {
        color: #348416;
    }

    .fg-gold {
        color: #C9B861;
    }

    .fg-red {
        color: #B60000;
    }
`;

const CustomContentBox = styled.div`
margin-top:10px;
.font-style{
  font-family: 'Poppins';
  letter-spacing: 0.005em;
  white-space: break-spaces;

}
    .font-11 {
        font-size: 11px;
    }

    .font-18 {
        font-size: 18px;
    }

    .font-13 {
        font-size: 13px;
    }
    .font-14{
      font-size:14px;
    }
    .bold-400 {
        font-weight: 400;
    }

    .bold-600 {
        font-weight: 600;
    }

    .fg-blue2 {
        color: #123FBC;
    }

    .fg-black {
        color: #3D3D3D;
    }

    .fg-gray5 {
        color: #ADADAD;
    }

    .fg-green2 {
        color: #348416;
    }

    .fg-gold {
        color: #C9B861;
    }

    .fg-red {
        color: #B60000;
    }

    .fg-gray4 {
        color: #535353;
    }

    .bg-lightblue2 {
        background-color: #F3F5FA;
    }
`;