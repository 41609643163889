import axios from "./axios";

const getValidToken = (type,token) => {
  let session_token = sessionStorage.getItem("temporary_access_token");
  let header_token = axios.defaults.headers.common['Authorization'];
  let finalToken = {}
  if(!session_token && !header_token)
    return;
  else if(session_token && header_token || session_token)
  {
    if(type === 'guide-comments')
    {
      finalToken["X-Guide-Access-Token"] = token?token:session_token;
    }
    else 
    {
      finalToken["X-Interviewer-Request-Access-Token"] = session_token;
    }

    finalToken["Authorization"]= null;
  }
  else
  {
    finalToken["Authorization"] = header_token;
  }
  return finalToken;
}

export const getGuidePreviewDataAPI = (guide_id, params)=>{
  const tokenObj = getValidToken();
  if(!tokenObj)
  return new Promise((resolve,reject)=>reject({response:'Unauthorized access'}))
  let guideUrl = `/guides/${guide_id}`;
    return axios.get(guideUrl, {
      params,
      headers:{...tokenObj}
    }).then(res=>{
      return res;
    }).catch(error=>{
      return error?.response;
  })
}


/*          Interview Guide Comments          */

const getTokenConfig = (token) => {
  let config ={};
  if(token)
  {
    config = {
      headers : {
        "X-Guide-Access-Token":token,
      }
    }
  }
  return config;
}
// Get all comments in a guide
export const getAllGuideCommentsAPI = (payload, token) => {
    const tokenObj = getValidToken('guide-comments',token);
    if(!tokenObj)
    return new Promise((resolve,reject)=>reject({response:'Unauthorized access'}))
    return axios.post(`/guide-comment/all`,payload,{
      headers:tokenObj,
    }).then(response=>{
      return response;
    }).catch(error=>{
      throw error?.response;
  });
}

//  Add Comments to a particular section in guide
export const addCommentAPI = (payload,token) => {
  const tokenObj = getValidToken('guide-comments',token);
  if(tokenObj)
  return new Promise((resolve,reject)=>reject({response:'Unauthorized access'}))
  return axios.post(`/guide-comment/add`,payload,{
    headers:tokenObj,
  }).then(response=>{
    return response;
  }).catch(error=>{
    throw error?.response;
  });
}

// Edit status of a comment
export const editCommentStatusAPI = (payload,token) => {
  const tokenObj = getValidToken('guide-comments',token);
  if(!tokenObj)
  return new Promise((resolve,reject)=>reject({response:'Unauthorized access'}))
  return axios.post(`/guide-comment/edit-status`,payload,{
    headers:tokenObj,
  }).then(response => {
    return response;
  }).catch(error => {
    throw error?.response;
  });
}

//  Add thread to a comment
export const addThreadAPI = (payload,token) => {
  const tokenObj = getValidToken('guide-comments',token);
  if(!tokenObj)
  return new Promise((resolve,reject)=>reject({response:'Unauthorized access'}))
    return axios.post(`/guide-comment/add-thread`,payload,{
      headers:tokenObj,
    }).then(response=>{
        return response;
    }).catch(error=>{
      throw error?.response;
  });
}

//  Delete a thread
export const deleteThreadAPI = (payload,token) => {
  const tokenObj = getValidToken('guide-comments',token);
  if(!tokenObj)
  return new Promise((resolve,reject)=>reject({response:'Unauthorized access'}))
  return axios.post(`/guide-comment/delete-thread`,payload,{
    headers:tokenObj,
  }).then(response => {
    return response;
  }).catch(error => {
    throw error?.response;
  });
}