import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButtonMUI from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DoneIcon from "@mui/icons-material/Done";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { Tooltip } from "../../../atoms/Tooltip/Tooltip";
import { useState, useEffect } from "react";
import SwtAlert from "../../../../Utils/SwtAlert";
import moment from "moment-timezone";
import "./CommentStyle.scss";
import { CommentStatus } from "../Constants";
import { DashboardType } from "../../../../constants";
import validator from 'validator';
import boatIcon from "../../../../assets/images/boatIcon.svg";
import orangeWarningIcon from "../../../../assets/images/orangeWarningIcon.svg";

const IconButton = styled(IconButtonMUI)({
  "&:hover": {
    background: "#D8E5FF",
  },
});

export const RightCommentBox = ({
  data,
  setData,
  comments,
  guideCommentAPIS,
  type,
  subType,
  showNewCommentBox,
  setShowNewCommentBox,
  isGuideForm
}) => {
  const { addCommentAPI, editCommentStatusAPI, addThreadAPI, deleteThreadAPI } =
    guideCommentAPIS;
  const [showBox, setShowBox] = useState([]);
  const [message, setMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const token = window?.sessionStorage.getItem('temporary_access_token');
  const validate = () => {
    if (!data?.name || data?.name?.trim()?.length === 0 || !validator?.isEmail(data?.email)) {
      SwtAlert("User Name or Email not valid", 2000, "error", false);
      return false;
    }
    if (!message || message?.trim()?.length === 0) {
      SwtAlert("Comment can't be empty", 2000, "error", false);
      return false;
    }
    return true;
  };
  const handleAddComment = () => {
    if (validate() === false) {
      return;
    }
    const payload = {
      guide_id: data?.guide_id,
      section: subType,
      comment: message,
      commentor_name: data.name,
      commentor_email: data.email,
    };
    addCommentAPI(payload,token)
      .then((res) => {
        res = res?.data;
        SwtAlert(
          res?.msg || res?.data || res,
          2000,
          "success",
          false
        );
        data.data[type][subType].push(res.data);
        showNewCommentBox.status = !showNewCommentBox?.status;
        setShowNewCommentBox({...showNewCommentBox});
        setMessage("");
        setData({...data});
      
      })
      .catch((error) => {
        SwtAlert(
          error?.response || error?.msg || error?.data || error?.toString(),
          2000,
          "error",
          false
        );
      });
  };
  const validateReply = (index) => {
    if(!showBox[index].message || showBox[index].message?.trim()?.length === 0)
    {
      SwtAlert("Reply can't be empty",2000,'error',false)
      return false;
    }
    return true;  
  }
  const handleAddReply = (index, item) => {
    if(!validateReply(index)){
      return;
    }
    const payload = {
      guide_id: data?.guide_id,
      guide_comment_id: item?.id,
      comment: showBox[index].message,
      commentor_name: data?.name,
      commentor_email: data?.email,
    };
    addThreadAPI(payload,token)
      .then((res) => {
        res = res?.data;
        SwtAlert(
          res?.msg || res?.data || res,
          2000,
          "success",
          false
        );
        showBox[index].message = "";
        data.data[type][subType][index] = res?.data;
        setShowBox([...showBox]);
        setData({ ...data });
      })
      .catch((error) => {
        SwtAlert(
          error?.response || error?.msg || error?.data || error?.toString(),
          2000,
          "error",
          false
        );
      });
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setMessage(value);
  };
  const handleReplyInputChange = (e, index) => {
    showBox[index].message = e.target.value;
    setShowBox([...showBox]);
  };

  const handleRejectClick = (e,item,index) => {
    const payload = {
      guide_id: data?.guide_id,
      guide_comment_id: item?.id,
      status: CommentStatus.DELETED,
      updater_name: data?.name,
      updater_email: data?.email,
    };
    handleEditStatus(e,payload,index,'reject');
  };
  const handleResolveClick = (e,item,index) => {
    const payload = {
      guide_id: data?.guide_id,
      guide_comment_id: item?.id,
      status: CommentStatus.RESOLVED,
      updater_name: data?.name,
      updater_email: data?.email,
    };
    handleEditStatus(e,payload,index);
  };
  const structureDataAfterEdit = (e,commentId,editType,index,resData)=>{
    const copyData = {...data};
    if(editType !== "reject")
    {
      copyData.data[type][subType][index] = resData;
        setData({...data});
    }
    else
    {
    const temp = copyData?.data[type]?.[subType]?.filter(item=>item?.id!==commentId);
    copyData.data[type][subType] = temp;
    }
    setData(copyData)
    e?.stopPropagation();
  } 
  const handleEditStatus = (e,payload,index,editType) => {
    setIsButtonDisabled(true);
    editCommentStatusAPI(payload,token)
      .then((res) => {
        setIsButtonDisabled(false);
        res = res?.data;
        SwtAlert(
          res?.msg || res?.data || res,
          2000,
          "success",
          false
        );
        structureDataAfterEdit(e,payload?.guide_comment_id,editType,index,res?.data);
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        SwtAlert(
          error?.response || error?.msg || error?.data || error?.toString(),
          2000,
          "error",
          false
        );
      });
  };
  const handleDeleteThread = (index, comment_id, uuid) => {
    const payload = {
      guide_id: data?.guide_id,
      guide_comment_id: comment_id,
      guide_comment_thread_id: uuid,
      updater_name: data?.name,
      updater_email: data?.email,
    };
    setIsButtonDisabled(true);
    deleteThreadAPI(payload,token)
      .then((res) => {
        setIsButtonDisabled(false);
        res = res?.data;
        SwtAlert(
          res?.msg || res?.data || res,
          2000,
          "success",
          false
        );
        showBox[index].message = "";
        data.data[type][subType][index] = res?.data;
        setShowBox([...showBox]);
        setData({ ...data });
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        SwtAlert(
         error?.response || error?.msg || error?.data || error?.toString(),
          2000,
          "error",
          false
        );
      });
  };
  const resetAll = (mainIndex, index, type) => {
    showBox?.forEach((list, ind1) => {
      if (type === "thread" || mainIndex !== ind1) list.options = false;
      list?.thread?.forEach((item, ind2) => {
        if (ind1 === mainIndex && ind2 === index) return item;
        item.options = false;
        return item;
      });
    });
  };

  const handleShowOptions = (index) => {
    resetAll(index);
    showBox[index].options = !showBox[index].options;
    setShowBox([...showBox]);
  };

  const isValid = (item) => {
    if (
      data?.dashboardType === DashboardType.OPS ||
      data?.email?.toLowerCase() ===
        item?.created_by?.commentor_email?.toLowerCase() ||
      data?.email?.toLowerCase() ===
        item?.created_by?.created_by_email?.toLowerCase()
    ) {
      return true;
    }
    return false;
  };
  const getValue = (type, item) => {
    if (item?.created_by?.created_by_email) {
      if (type === "email") {
        if (isValid(item)) return item?.created_by?.created_by_email;
        else return item?.created_by?.owner;
      } else {
        if (isValid(item)) return item?.created_by?.created_by_name;
        else return "";
      }
    } else {
      if (type === "email") {
        if (isValid(item)) return item?.created_by?.commentor_email;
        else return item?.created_by?.owner;
      } else {
        if (isValid(item)) return item?.created_by?.commentor_name;
        else return "";
      }
    }
  };

  useEffect(() => {
    const tempArr = comments?.map((item) => {
      let obj = {
        options: false,
        thread: [],
      };
      item?.guide_comment_thread?.forEach((thread) => {
        obj.thread.push({
          options: false,
          message: "",
        });
      });
      return obj;
    });
    setShowBox(tempArr);
  }, [comments,data]);

  const renderNewCommentContainer = () => {
    return (
      <Box className={`preview-comment-box`}>
        <Box className="user-details" padding="10px">
          <Typography className="text-1">{data?.name}</Typography>
          <Typography className="text-2">{data?.email}</Typography>
          <Typography className="text-2">
            {moment().format("h:mm a")}, Today
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          paddingLeft="10px"
          paddingRight="10px"
          paddingBottom="10px"
        >
          <TextField
            className="reply-input"
            placeholder="Add comment"
            name="reply"
            multiline
            minRows="1"
            size="small"
            value={message}
            onChange={handleInputChange}
            sx={{
              padding: "0px",
              "& legend": {
                display: "none",
              },
            }}
            inputProps={{
              style: {
                padding: "0px",
              },
            }}
          />
          <Button className="submit-reply-btn" onClick={handleAddComment}>
            Comment
          </Button>
        </Box>
      </Box>
    );
  };
  const renderAllComments = () => {
    return (
      <Box className={`shared-guide-preview-comment-box ${isGuideForm && 'removePositionProperty'}`}>
        {renderNewCommentContainer()}
        {comments &&
          comments?.length > 0 &&
          comments?.map((item, index) => {
            return (
              <Box className="preview-comment-box">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Box padding="10px" className="user-details">
                    {item?.created_by?.commentor_name !== 'AUTO_COMMENT' && 
                     <>
                         <Typography className="text-1">
                               {getValue("name", item)}
                          </Typography>
                        <Typography className="text-2">
                               {getValue("email", item)}
                        </Typography>
                     </>
                     }

                     {item?.created_by?.commentor_name === 'AUTO_COMMENT' && 
                         <Box className="boatHeadingBox">
                            <img  src={boatIcon}/>
                            <Typography className="boatHeadingText">InterviewVector Bot</Typography>

                         </Box>
                     }
                   
                    <Typography className="text-2">
                      {moment(item?.created_at * 1000).format("h:mm a")}{" "}
                      {moment(item?.created_at * 1000).format("DD/MM/YYYY")}
                    </Typography>
                  </Box>
                  {
                  item?.status === CommentStatus.RESOLVED ? (
                    <Box>
                      <Typography className="text-3">
                        {CommentStatus.RESOLVED}
                      </Typography>
                    </Box>
                  ) : (
                    <Box display="flex">
                      {item?.created_by?.commentor_name !== 'AUTO_COMMENT' && 
                              <Box>
                              <Tooltip title="Mark as resolved">
                                <IconButton
                                  onClick={(e) => handleResolveClick(e,item,index)}
                                  aria-label="comment"
                                >
                                  <DoneIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                      }

                      {
                           <Box>  
                               <IconButton
                                 onClick={() => handleShowOptions(index)}
                                 aria-label="comment"
                               >
                                 <MoreVertIcon />
                               </IconButton>
                           </Box>  
                      }
                   
                   
                      {showBox[index]?.options ? (
                        <Box
                          className="options-box"
                          display="flex"
                          flexDirection="column"
                        >
                          <Button
                            onClick={(e) => handleRejectClick(e,item,index)}
                            className={
                              isButtonDisabled
                                ? "text-1 red-bg disabled-btn"
                                : "text-1 red-bg"
                            }
                            disabled={isButtonDisabled}
                          >
                            {isButtonDisabled
                              ? "Please wait..."
                              : "Mark as Reject"}
                          </Button>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                  )}
                </Box>
                <Box paddingLeft="10px" paddingBottom="10px">
                {item?.created_by?.commentor_name !== 'AUTO_COMMENT' &&
                      <Typography paddingRight="10px" className="text-1 word-break">
                        {item?.comment}
                      </Typography>
                }
                  {item?.created_by?.commentor_name === 'AUTO_COMMENT' && 
                    <Box className="frequentlyAskedBox">
                      <img src={orangeWarningIcon}/>
                      <Typography className="frequentlyAskedText">Question asked too frequently. Please change the question. </Typography>
                    </Box>
                  }

                  {item?.created_by?.commentor_name === 'AUTO_COMMENT' && 
                    <Box className="questionBox">
                      Q. {item?.comment}
                    </Box>
                  }
                  <Divider light className="comments-divider" />
                  {renderAllReply(item, index)}
                  <Box display="flex" flexDirection="column" gap="8px">
                    <Box>
                      <TextField
                        className="reply-input"
                        placeholder="Add reply"
                        name="reply"
                        multiline
                        minRows="1"
                        size="small"
                        value={showBox[index]?.message}
                        onChange={(e) => handleReplyInputChange(e, index)}
                        sx={{
                          padding: "0px",
                          "& legend": {
                            display: "none",
                          },
                        }}
                        inputProps={{
                          style: {
                            padding: "0px",
                          },
                        }}
                      />
                    </Box>
                    <Box>
                      <Button
                        className="submit-reply-btn"
                        onClick={() => handleAddReply(index, item)}
                      >
                        Reply
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
    );
  };

  const renderAllReply = (item, mainIndex) => {
    const toggleOptions = (index) => {
      resetAll(mainIndex, index, "thread");
      showBox[mainIndex].thread[index].options =
        !showBox[mainIndex]?.thread[index]?.options;
      setShowBox([...showBox]);
    };
    return (
      <>
        {item?.guide_comment_thread?.map((thread, index) => {
          return (
            <Box display="flex" flexDirection="column" gap="4px">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box className="user-details">
                  <Typography className="text-1">
                    {getValue("name", thread)}
                  </Typography>
                  <Typography className="text-2">
                  {getValue("email", thread)}
                  </Typography>
                  <Typography className="text-2">
                    {moment(item?.created_at * 1000).format("h:mm a")}{" "}
                    {moment(item?.created_at * 1000).format("DD/MM/YYYY")}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => toggleOptions(index)}
                    aria-label="comment"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  {showBox[mainIndex]?.thread[index]?.options ? (
                    <Box
                      className="options-box options-box-2"
                      display="flex"
                      flexDirection="column"
                    >
                      <Button
                        onClick={() =>
                          handleDeleteThread(
                            mainIndex,
                            item?.id,
                            thread?.guide_comment_thread_id
                          )
                        }
                        disabled={isButtonDisabled}
                        className={
                          isButtonDisabled
                            ? "text-1 red-bg disabled-btn"
                            : "text-1 red-bg"
                        }
                      >
                        {isButtonDisabled ? "Please wait..." : "Delete Thread"}
                      </Button>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
              <Box>
                <Typography paddingRight="10px" className="text-1 word-break">
                  {thread?.comment}
                </Typography>
                <Divider light className="comments-divider" />
              </Box>
            </Box>
          );
        })}
      </>
    );
  };

  return <>{renderAllComments()}</>;
};