import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CommentWrapper, QuestionWithoutTopic, QuestionWithTopic,} from "../common";
import { StagesType, TheoreticalType } from "../../Constants";
import ReferenceImageModal from "../ReferenceImageModal";
import SolutionModal from "../SolutionModal";
const TheoreticalSection = ({
    guideData,
    topicList,
    downloadFile,
    isIndepthKnowledge,
    getTheoreticalQuestions,
    theoreticalQuestionTopics,
    commentData,
    ...props
}) => {
    const [imageReferenceModal, setImageReferenceModal] = useState({
        status: false,
        data: null,
    });
    const [solutionModal, setSolutionModal] = useState({
        status: false,
        data: null,
    });
    const theoreticalData = guideData[StagesType.THEORETICAL]?.sections;

    const openImageReference = (data) => {
        setImageReferenceModal((prev)=>({...prev, status: true, data}))
    }

    const closeImageReference = () => {
        setImageReferenceModal((prev)=>({...prev, status: false, data: null}))
    }

    const openSolutionModal = (data) => {
        setSolutionModal((prev)=>({...prev, status: true, data}))
    }

    const closeSolutionModal = () => {
        setSolutionModal((prev)=>({...prev, status: false, data: null}))
    }

    const renderSectionDetail = () => {
        let totalCount = 0;
        theoreticalData?.map(item => {
            if(item?.type === "THEORETICAL_QUESTIONS_DISTRIBUTION") {
                totalCount = item?.values?.total;
            }
        });
        return (
            <Box className="head-detail-wrapper">
            <Typography className="common-text">Total number of questions to ask: <span className="bold-600">{totalCount}</span></Typography>
            {
                theoreticalData?.[0]?.values?.total > 0 &&  theoreticalData?.[0]?.values?.question_topic && Object.keys(theoreticalData?.[0]?.values?.question_topic)?.length > 0  && !isIndepthKnowledge && (
                    <Box className="topic-main-wrapper">
                        <Typography className="common-text">From the below topic list:</Typography>
                        {topicList()}
                    </Box> 
                    )
            }
            <Box className="difficulty-border-wrapper">
                <span className="easy-border-chip">Easy </span>
                <span className="medium-border-chip">- Medium </span>
                <span className="hard-border-chip">- Hard</span>
            </Box>
        </Box>
        )
    }

    

    const renderSectionBody = () => {
        let questionList = getTheoreticalQuestions() || [];
        return <Box className="question-box-main-container">
                        <Box className="question-head-wrapper ">
                            <Typography className="head-text">Questions List</Typography>
                            <Typography className="head-sub-text">These are the questions suggested for you to ask.</Typography>
                        </Box>
                        <Box className="question-list-main-wrapper">
                        { 
                            theoreticalQuestionTopics?.length === 0 ? 
                                <QuestionWithoutTopic
                                type="THEORETICAL"
                                questionList={questionList}
                                openImageReference={openImageReference}
                                openSolutionModal={openSolutionModal}
                                />  : "" }
                    { 
                        theoreticalQuestionTopics?.length > 0 ? 
                            <QuestionWithTopic
                            type="THEORETICAL"
                            questionList={questionList}
                            questionTopicData={theoreticalQuestionTopics}
                            openImageReference={openImageReference}
                            openSolutionModal={openSolutionModal}
                            /> : ""}
                        </Box>
                </Box>
    }
    return (
        <Box className="common-section-main-wrapper">
         <CommentWrapper
            stageType={StagesType.THEORETICAL}
            sectionType={TheoreticalType.THEORETICAL_QUESTIONS_DISTRIBUTION}
            sectionCommentData={commentData?.data?.[StagesType.THEORETICAL]?.[TheoreticalType.THEORETICAL_QUESTIONS_DISTRIBUTION]}
            commentData={commentData}
            {...props}
            >
          {renderSectionDetail()}
          </CommentWrapper>
          <CommentWrapper 
            stageType={StagesType.THEORETICAL}
            sectionType={TheoreticalType.THEORETICAL_QUESTIONS}
            sectionCommentData={commentData?.data?.[StagesType.THEORETICAL]?.[TheoreticalType.THEORETICAL_QUESTIONS]}
            commentData={commentData}
            {...props}
            >
          {renderSectionBody()}
          </CommentWrapper>
          <ReferenceImageModal
          imageReferenceModal={imageReferenceModal}
          closeImageReference={closeImageReference}
          downloadFile={downloadFile}
          />
           <SolutionModal
            section={"Theoretical Question"}
            solutionModal={solutionModal}
            closeSolutionModal={closeSolutionModal}
            isIndepthKnowledge={isIndepthKnowledge}
            />
        </Box>
    )
}

export default TheoreticalSection;