import React from 'react';
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./ReportSection.scss";
import moment from "moment-timezone";
import rightTick from "../../../../assets/images/right_tick.svg";
import switchedTab from "../../../../assets/images/switched-tab.svg";
import { PRESCREENING_RESULT } from '../Constant';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PRESCREEN_TOPIC } from '../Constant';
import { OPTIONS_LABEL } from '../../PrescreeningListForm/Constants';
const INTEGER = "INT_OUTPUT_BASED";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F3F5FA",
    color: "#3D3D3D",
    fontSize: "14px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
  },
  fontFamily: "Poppins",
}));

const hideBorder = {
  borderBottom:"none !important"
}

const StyledTableCellTopic = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F3F5FA",
      color: "#3D3D3D",
      fontSize: "14px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "12px",
    },
    [`& .head-txt`] : {
      fontFamily: "Poppins",
      color:"#123FBC",
      fontSize:"14px",
      fontWeight:"600",
      textTransform:"capitalize"
    },
    borderBottom:"none",
    padding:"0px",
    paddingLeft:"16px",
    paddingTop:"16px",
  }));

const ReportSection =({ 
  positionDetails, 
  candidatePrescreeningRoundDetails, 
  reportQuestions, 
  prescreeningDetails,
  prescreenQuestion,
  setPrescreenQuestion,
  prescreenQuesImage,
}) =>{

    const [questionsList, setQuestionsList] =  useState([]);
    const [cuttOff, setCuttOff] = useState(null);
    const [modifiedList, setModifiedList] = useState([]);
    const [timeAlloted, setTimeAlloted] = useState(null);
    const correctScore = candidatePrescreeningRoundDetails?.candidate_result_info?.positive_score;
    const inCorrectScore = candidatePrescreeningRoundDetails?.candidate_result_info?.negative_score;
    const totalScore =  candidatePrescreeningRoundDetails?.candidate_result_info?.total_score;
    const tabSwitchCount = (candidatePrescreeningRoundDetails?.extra_info?.tab_switch_count);
    const startTimestamp = moment(candidatePrescreeningRoundDetails?.extra_info?.actual_start_timestamp);
    const endTimeStamp = moment(candidatePrescreeningRoundDetails?.extra_info?.actual_end_timestamp);
    const testTime = moment(candidatePrescreeningRoundDetails?.extra_info?.actual_start_timestamp * 1000);
    var timeDifference = moment.utc(moment(endTimeStamp*1000).diff(moment(startTimestamp*1000)));
    let minutes = Math.floor(timeDifference/60000);
    const testCompletionTime = timeDifference.format("mm:ss");
    useEffect(() => {

          if(questionsList?.length > 0) {
            let List = [...questionsList];
            List.map((item, index) => {
                        candidatePrescreeningRoundDetails?.candidate_response?.answers.map((data, index) => {
                            if(item?.id == data?.question_id) {
                                    item.candidate_response = data?.question_response;
                                    item.candidate_result = data?.question_result;
                                    item.marks_secured = data?.marks_secured;
                            }
                        })
                         
                    })
                    setModifiedList(List);
          }

    },[questionsList]);

    useEffect(()=>{
    if(modifiedList)
    {
      const topics = {};
      modifiedList?.map(question=>{
        if(question?.topic && question?.topic?.length > 0)
        {
          if(!topics.hasOwnProperty(question?.topic))
          {
            topics[`${question?.topic}`] = []
          }
            if(!topics[`${question?.topic}`] || topics[`${question?.topic}`]?.length === 0)
            {
              topics[`${question?.topic}`] = [question];
            }
            else
            {
              topics[`${question?.topic}`]?.push(question);
            }
        }
        else
        {
          if(!topics.hasOwnProperty(PRESCREEN_TOPIC.OTHERS))
          {
            topics[`${PRESCREEN_TOPIC.OTHERS}`] = []
          }
          if(!topics[`${PRESCREEN_TOPIC.OTHERS}`] || topics[`${PRESCREEN_TOPIC.OTHERS}`]?.length === 0)
          {
            topics[`${PRESCREEN_TOPIC.OTHERS}`] = [question];
          }
          else
          {
            topics[`${PRESCREEN_TOPIC.OTHERS}`]?.push(question);
          }
        }
      });
      setPrescreenQuestion(topics)
         }
    },[modifiedList])

    useEffect(() => {
        // setQuestionsList(positionDetails?.questions);
        setQuestionsList(reportQuestions);
         setCuttOff(positionDetails?.cut_off_percentage);
         setTimeAlloted(positionDetails?.time_allotted);
    },[positionDetails])

  return (
    <Box className='report-section-parent'>
            {/* header part */}
            <Box className='report-header'>
                   {/* heading box */}
                  
                  <Box display={'flex'} justifyContent='space-between'>

                   <Box>
                      <Typography className='stats-text'>Overall Stats</Typography>
                      <Typography className='common-text margin-top-5'><span className='bold-text'>Cutoff</span> for this round is set to be <span className='bold-text'>{cuttOff}%</span> 
                      {positionDetails?.tab_switch_check?.count ? <span> and tabs switched <span className='bold-text'> {"<"}{positionDetails?.tab_switch_check?.count}</span></span> : ""}
                      </Typography>
                   </Box>


                  {tabSwitchCount>0 && <Box className='horizontal-container switch-tab' gap={1}>

                      <img src={switchedTab} className="right-tick" />
                      <Typography className='text'> Switched Tabs: <span className='value'>{tabSwitchCount} times</span></Typography>

                  </Box>}

                  {tabSwitchCount === 0 && <Box className='horizontal-container switch-tab bg-green' gap={1}>
                      <Typography className='text'> Switched Tabs: <span className='value'>{tabSwitchCount} times</span></Typography>
                  </Box>}

                  </Box>
                 

                   {/* horizontal boxex parent box */}
                   <Box className='horizontal-container'>
                            <Box className={`result-container ${candidatePrescreeningRoundDetails?.candidate_result_info?.result === PRESCREENING_RESULT.PROCEED ? "bg-green" : "bg-red"}`}>
                                  <Box className='pass'>
                                      <Typography className={`pass-text ${candidatePrescreeningRoundDetails?.candidate_result_info?.result === PRESCREENING_RESULT.PROCEED ? "green-text" : "red-text"}`}>
                                        {candidatePrescreeningRoundDetails?.candidate_result_info?.result === PRESCREENING_RESULT.PROCEED ? "PASSED" : "FAILED"} ({Math?.trunc(candidatePrescreeningRoundDetails?.candidate_result_info?.percentage)}%)
                                      </Typography>
                                        {candidatePrescreeningRoundDetails?.candidate_result_info?.result === PRESCREENING_RESULT.REJECT && 
                                        candidatePrescreeningRoundDetails?.candidate_result_info?.percentage > cuttOff && 
                                        tabSwitchCount > positionDetails?.tab_switch_check?.count ? 
                                        <span className='ml-10'> <img src={switchedTab} width="20px" className="right-tick" /></span> : ""
                                        }
                                  </Box>
                                  {totalScore !== null && totalScore !==undefined  && 
                                  <Typography className='percentage-text bold-400 ans-margins'>Points Scored: <span className='bold-600'>
                                    {correctScore === 0 && inCorrectScore===0  ? 
                                       <>
                                            (<span className='red-text'>0</span>)
                                       </> 
                                       :
                                       <>
                                               ({ correctScore !==0 && <span className='green-text'>+{correctScore}</span>} {" "} 
                                               {prescreeningDetails?.negative_marking &&   inCorrectScore !== 0 && <span className='red-text'>{inCorrectScore}</span>})
                                       </>
                                    }
                                    </span> out of <span className='bold-600'>{totalScore}</span> points.
                                  </Typography>}

                            </Box>
                            <Box className='start-time-container'>
                                 <Typography className='start-time-text margin-left'>Test Start Time</Typography>
                                 <Typography className='time  margin-left'>{testTime.format("hh:mm A")}</Typography>
                                 <Typography className='time font-12  margin-left'>{testTime.format("MMM DD, YYYY")}</Typography>
                            </Box>
                            <Box className='duration-container'>
                                <Typography className='start-time-text margin-left'>Test completion time</Typography>
                                <Typography className='duration-text margin-left'>{testCompletionTime} <span className='font-17'>{minutes == 0 ? "sec" : "min"}</span><span className='font-17'></span></Typography>
                                <Typography className='start-time-text margin-left'> Total test time: <b>{prescreeningDetails?.time_allotted} mins</b></Typography>
                            </Box>
                   </Box>
            </Box>

            <hr className='divider'></hr>

            {/* body part */}
            <Box className='report-body'>
                <TableContainer sx={{border:"1px solid #D6D9E2", borderRadius:"14px", width:"fit-content", minWidth:"820px"}}>
                    <Table aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell width="70%">Questions</StyledTableCell>
                            <StyledTableCell align="right">Response</StyledTableCell>
                            <StyledTableCell align="right">Difficulty Level</StyledTableCell>
                            <StyledTableCell align="right">Points Earned</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                prescreenQuestion && Object.entries(prescreenQuestion)?.map(([topic,questions],i)=>{
                                    return questions?.map((data,index) => (
                                        <>
                                        {
                                            index === 0 ? 
                                            <TableRow>
                                                <StyledTableCellTopic component="th" scope="row" width="58%" >
                                                    <Typography className="head-txt">{topic === PRESCREEN_TOPIC.OTHERS?"Generic Questions":topic}</Typography>
                                                </StyledTableCellTopic>
                                            </TableRow>
                                            :null
                                        }
                                        <TableRow key={index}>
                                            <StyledTableCell sx={index !== questions.length-1?hideBorder:''} component="th" scope="row" width="70%" >
                                                <Box className='qustn-box font-600' display="flex" gap="4px">
                                                  <Box>Q{index + 1}. </Box>
                                                  <Box className='ql-editor quill-box' dangerouslySetInnerHTML={{__html: data?.question_text || ""}}></Box>
                                                </Box>
                                                { data?.question_code && data?.question_code?.length > 0 && 
                                                <Box className='code-box'>
                                                    <Typography className='code-text'><pre><code>{data?.question_code}</code></pre></Typography>
                                                </Box>
                                                }
                                                { data?.question_image &&
                                                <Box className='image-box'>
                                                  <img src={prescreenQuesImage?.[topic]?.[index]} className="image-style" alt='question-image' />
                                                </Box>
                                                }
                                                { data?.type === INTEGER ?
                                                <Box className='int-answer'>
                                                  {data?.candidate_response ? `Candidate Answer = ${data?.candidate_response?.[0]}` : ""}
                                                </Box>
                                                :
                                                <Box className='ps-option-box'>
                                                <Typography>
                                                  <ul className='question-text font-400 mt-11'>
                                                    {data?.options_json && Object.entries(data?.options_json)?.map(([option, value]) => {
                                                      return (
                                                        <li><pre>{value}{data?.answer_json?.includes(OPTIONS_LABEL[option]) && <img src={rightTick} className="right-tick" />}</pre></li>
                                                      )
                                                    })}
                                                  </ul>
                                                </Typography>
                                                </Box>
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell sx={index !== questions.length-1?hideBorder:''} align="right">
                                                <Box className={`qstn-result ${data.candidate_response == null ? "unattempted" : data.candidate_result === PRESCREENING_RESULT.INCORRECT ? "incorrect" : "correct" }`}>
                                                    {data.candidate_response == null ? "unattempted" : data.candidate_result === PRESCREENING_RESULT.INCORRECT ? "incorrect" : "correct" }
                                                </Box>
                                            </StyledTableCell>
                                            <StyledTableCell sx={index !== questions.length-1?hideBorder:''} align="right" className={`font-600 ${data?.difficulty_level?.toUpperCase()==='EASY'?"green-text" : data?.difficulty_level?.toUpperCase()==='MEDIUM'?"gold-text" : "red-text"}`}>
                                                {data?.difficulty_level?.toUpperCase()==='EASY' ? 'Easy' : data?.difficulty_level?.toUpperCase()==='MEDIUM' ? 'Medium' : 'Hard'}
                                            </StyledTableCell>
                                            <StyledTableCell sx={index !== questions.length-1?hideBorder:''} align="right" className="font-600">{data?.marks_secured}</StyledTableCell>
                                        </TableRow>
                                        </>
                                    ))
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
    </Box>
  )
}

export default ReportSection;