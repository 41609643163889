import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CommentWrapper, renderBulletPoint } from "../common";
import { GuidelineIntroductionType, StagesType } from "../../Constants";
import { getRatingRange } from "../../Components/ResultClarity";
const GuidelineSection = ({
    guideData,
    commentData,
    ...props
}) => {
    const guideLinesData = guideData[StagesType.GUIDELINES_INTRODUCTION]?.sections;

    const renderGeneric = () => {
        let genericGuideData = [];
        guideLinesData?.map( item => {
            if(item?.type === "GENERIC_INSTRUCTIONS") {
                let arr1 = item?.default?.filter(item=>item?.selected) || [];
                let arr2 = item?.extras?.filter(item=>item?.selected) || [];
                genericGuideData = [...arr1, ...arr2];
            }
        })

        return (
            <Box className="instruction-main-wrapper">
                <Typography className="common-head-text">1. GENERIC INSTRUCTIONS</Typography>
                <Box className="instruction-wrapper">
                    <Box className="instruction-inner-wrapper">
                    {
                        genericGuideData && genericGuideData?.map((item, index) => {
                            if(index%2 === 0)
                            return renderBulletPoint(`common-text ${item?.is_important ? 'imp-text' : ''}`,item?.label)
                        })
                    }
                    </Box>
                    <Box className="instruction-inner-wrapper">
                    {
                        genericGuideData && genericGuideData?.map((item, index) => {
                            if(index%2 !== 0)
                            return renderBulletPoint(`common-text ${item?.is_important ? 'imp-text' : ''}`,item?.label)
                        })
                    }
                    </Box>

                    {/* {renderBulletPoint("common-text imp-text","If candidate is taking more time, note it in feedback, & move forward.")} */}
                    
                </Box>
            </Box>
        )
    }
    const renderEvalutaion = () => {
        let ratingGuideData = [];
        let strongProceedObj = null;
        let proceedObj = null;
        let rejectObj = null;
        let strongRejectObj = null;

        guideLinesData?.map( item => {
            if(item?.type === "RATINGS") {
                ratingGuideData = item?.values;
            }
        });

        ratingGuideData && ratingGuideData?.map(item => {
            if(item.type === "STRONG_PROCEED") 
                strongProceedObj = item;
            else if(item.type === "PROCEED")
                proceedObj = item;
            else if(item.type === "REJECT")
                rejectObj = item;
            else if(item.type === "STRONG_REJECT")
                strongRejectObj = item;
        })

        const getStyle = (type) => {
            let style = "";
            if(type === "STRONG_PROCEED") 
                style = "strong-proceed";
            else if(type === "PROCEED")
                style = "proceed";
            else if(type === "REJECT")
                style = "reject";
            else if(type === "STRONG_REJECT")
                style = "strong-reject";

            return style;
        }


        return (
            <Box className="evaluation-main-wrapper">
                <Typography className="common-head-text">2. EVALUATION CRITERIA</Typography>
                <Box>
                    <table className="table-main-wrapper">
                        <thead>
                            <tr>
                                <th>Rating</th>
                                <th>Recommendation</th>
                                <th>Description</th>
                            </tr>    
                        </thead>
                        <tbody>
                            {
                                ratingGuideData && ratingGuideData?.map(item => {
                                    return (
                                        <tr className={getStyle(item?.type)}>
                                            <td>{getRatingRange(item?.range)}</td>
                                            <td className="result-text">{item?.label}</td>
                                            <td>{item?.hint}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Box>
            </Box>
        )
    }
    return (
        <Box className="guideline-section-main-wrapper">
            {/* Generic Guidelines */}
            <CommentWrapper
            stageType={StagesType.GUIDELINES_INTRODUCTION}
            sectionType={GuidelineIntroductionType.GENERIC_INSTRUCTIONS}
            sectionCommentData={commentData?.data?.[StagesType.GUIDELINES_INTRODUCTION]?.[GuidelineIntroductionType.GENERIC_INSTRUCTIONS]}
            commentData={commentData}
            {...props}
            >
                {renderGeneric()}
            </CommentWrapper>

            {/* Skill Evaluation */}
            <CommentWrapper
            stageType={StagesType.GUIDELINES_INTRODUCTION}
            sectionType={GuidelineIntroductionType.SKILL_EVALUATION}
            sectionCommentData={commentData?.data?.[StagesType.GUIDELINES_INTRODUCTION]?.[GuidelineIntroductionType.SKILL_EVALUATION]}
            commentData={commentData}
            {...props}
            >
           {renderEvalutaion()}
           </CommentWrapper>
        </Box>
    )
}
export default GuidelineSection;