import React from "react";

import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Icon from '@mui/material/Icon'
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecord';

import completeIcon from "../../../../../assets/images/feedback/feedback-complete-icon.png"
import incompleteIcon from "../../../../../assets/images/feedback/feedback-incomplete-icon.png"

import { CommentConstants } from "../../Constants/FeedbackConstants";

import './previewSection.scss';

import {AccordionSummary, AccordionDetails} from './../../Components/Accordion'

import { CodeBox } from "./CodeBox";
import { codeBoxHeading } from "./Constant";

export const TheorySection = ({ data, showFeedbackComments, renderAddCommentSection, renderViewUnresolvedCommentSection }) => {
  const star = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
  const [isCodingOpen,setIsCodingOpen] = React.useState(false);
  const [codeSolution,setCodeSolution] = React.useState('');
  const [readMore, setReadMore] = React.useState({});
  const [expanded, setExpanded] = React.useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded);
  }

  const groupQuestions = (list, key) => {
    return list?.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      console.log(rv)
      return rv;
    }, {});
  };

  
  return (
    <>
      <CodeBox
        isDialogOpen={isCodingOpen}
        handleClose={() => setIsCodingOpen(false)}
        solutionCode={codeSolution}
        type="theory"
        heading={codeBoxHeading?.THEORY}
      />
   
    <Accordion id="add-comment-parent" className="question-container" style={{ marginBottom: '20px' }}  expanded={expanded} onChange={handleChange('panel1')}>

      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box className="theory-question-header" style={{ width: "100%", alignItems: "center" }}>
          <Box className="theory-question-header-text">
            <Typography className="theory-question-title">THEORY QUESTIONS</Typography>
            <Typography className="theory-question-time">Time Spent: {data?.summary?.time_spent} Mins</Typography>
          </Box>
        </Box>
        {showFeedbackComments && renderViewUnresolvedCommentSection(CommentConstants.THEORY_QUESTIONS)}
        {showFeedbackComments && renderAddCommentSection(CommentConstants.THEORY_QUESTIONS)}
      </AccordionSummary>

      <AccordionDetails>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }}>

          {Object.keys(groupQuestions(data?.questions, 'group'))?.map((datum) => {
            return (
              <Box>
              {Object.keys(groupQuestions(data?.questions, 'group'))?.length === 1 && (datum !== 'undefined' && datum !== 'null') && <div className="theoretical-field-header">{datum}</div>}
              {Object.keys(groupQuestions(data?.questions,'group'))?.length > 1 && <div className="theoretical-field-header">{(datum !== 'undefined' && datum !== 'null') ? datum : "OTHERS"}</div>}

              <Box>
      {
        groupQuestions(data?.questions, 'group')[datum]?.map((que, index) => {

        return <Box key={index} className="theory-question-body">
                <Box className="theory-question-container">
                  <Box className="tq-container-header" display='row' justifyContent={'space-between'}>
                    <Box width="100%" display='flex' flexDirection='row'>  {index + 1 + ".  "}
                      <Box display="inline-block" className={( !readMore[index])?"question-statement question-text ql-editor quill-box":"question-text ql-editor quill-box"} 
                          style={{lineBreak:'anywhere'}} dangerouslySetInnerHTML={{ __html: (que?.question_statement || que?.question_asked ) }}>        
                    </Box>
                    { que?.question_statement?.length >=380 ?  readMore[index]?<Box onClick={()=> setReadMore({ ...readMore, [index]:false})} className="read-more">Read less</Box>:
                        <Box onClick={()=> setReadMore({ ...readMore, [index]:true})} className="read-more">Read more</Box>
                    : '' }

                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" gap="16px">
                     {que?.solution_code && <Box>
                        <Button className="view-code" onClick={()=>{setIsCodingOpen(!isCodingOpen); setCodeSolution(que?.solution_code);}}>View Code</Button>
                       
                      </Box>}
                    </Box>
                  
                  
                  </Box>
                  <Box className="tq-container-body">
                    <Box className="tq-container-topbar">
                      <Box className="question-difficulty-tag"
                        style={{ backgroundColor: que?.difficulty?.tag === "easy" ? "#3EAD2C" : que?.difficulty?.tag === "hard" ? "#B22B2B" : "#3D3D3D" }}>
                        <Typography className="question-difficulty-text">{que?.difficulty?.text}</Typography>
                      </Box>
                      <Box className="star-rating">
                        {star.map((item, index) => {
                          return (
                            <Icon key={index} ><StarRateRoundedIcon
                              sx={{
                                color: index < que?.rating ? "#F2C141" : "#E1E1E1",
                                verticalAlign: "top"
                              }} /></Icon>
                          )
                        })}
                      </Box>
                      <Box className="rating">
                        <Typography><span style={{ fontWeight: "700" }}>{'('}{que?.rating}</span>{'/10'}<span style={{ fontWeight: "700" }}>{')'}</span></Typography>
                      </Box>
                      <Box style={{ borderColor: que?.comment?.tag === "positive" ? "#3EAD2C" : que?.comment?.tag === "negative" ? "#B22B2B" : "#3D3D3D" }} className="question-remark">
                        <Box display={'flex'} alignItems='center'>
                          {que?.comment?.tag === "positive" 
                          ? <img style={{ width: '20px', height: '20px' }} src={completeIcon} alt='complete' /> 
                          : que?.comment?.tag === "negative" ? <img style={{ width: '10px', height: '10px', marginRight:'5px' }} src={incompleteIcon} alt='incomplete' /> 
                          : <FiberManualRecordRoundedIcon className="dot-icon" />}
                          <Typography
                            style={{ color: que?.comment?.tag === "positive" ? "#3EAD2C" : que?.comment?.tag === "negative" ? "#B22B2B" : "#3D3D3D" }}
                            className="question-remark-text">{que?.comment?.text}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {
                      (que?.positives?.length > 0 || que?.negatives?.length > 0 || que?.neutrals?.length > 0) &&
                      <Box className="tq-body-tables">
                        <Box className="positive-point-table">
                          <Box className="tq-table-header" style={{ borderRight: "1px solid #BCC7E5" }}>
                            <Typography className="table-header-text">Positives</Typography>
                          </Box>
                          <Box className="tq-table-body">
                            {que?.positives?.length !== 0 ? <Box>{que?.positives?.map((pos, index) => {
                              return (
                                <>
                                  <Box display={'flex'} alignItems='center'><img style={{ width: '20px', height: '20px', alignSelf: 'start', marginLeft: '10px' }} src={completeIcon} alt='complete' /><Typography className="table-body-title">{Object.keys(pos)}
                                    {Object.keys(pos).map((description, index) => {
                                      return (
                                        <>{
                                          pos[description]?.map((item) => {
                                            return (
                                              <>{item ?
                                                <Box>
                                                  <Typography className="table-body-description"> {`- ${item}`} </Typography>
                                                </Box> : <></>}
                                              </>)
                                          })
                                        }</>
                                      )
                                    })}
                                  </Typography></Box>

                                </>
                              )
                            })}</Box> : <Box style={{ display: "flex", color: "#8FAECF" }}>
                              <FiberManualRecordRoundedIcon className="dot-icon" style={{ color: "#8FAECF", marginTop: "6px" }} />
                              <Typography>
                                None</Typography></Box>}
                          </Box>
                        </Box>
                        <Box className="neutral-point-table">
                          <Box className="tq-table-header" style={{ borderRight: "1px solid #BCC7E5" }}>
                            <Typography className="table-header-text">Neutrals</Typography>
                          </Box>
                          <Box className="tq-table-body">
                            {que?.neutrals?.length !== 0 ? <Box>{que?.neutrals?.map((neu, index) => {
                              return (
                                <>
                                  <Box style={{ display: "flex" }}><FiberManualRecordRoundedIcon className="dot-icon" /><Typography className="table-body-title">{Object.keys(neu)}
                                    {Object.keys(neu).map((description, index) => {
                                      return (
                                        <>{
                                          neu[description].map((item) => {
                                            return (
                                              <>{item ?
                                                <Box>
                                                  <Typography className="table-body-description"> {`- ${item}`} </Typography>
                                                </Box> : <></>}
                                              </>)
                                          })
                                        }</>
                                      )
                                    })}
                                  </Typography></Box>

                                </>
                              )
                            })}</Box> : <Box style={{ display: "flex", color: "#8FAECF" }}>
                              <FiberManualRecordRoundedIcon className="dot-icon" style={{ color: "#8FAECF", marginTop: "6px" }} />
                              <Typography>None</Typography></Box>}
                          </Box>
                        </Box>
                        <Box className="negative-point-table">
                          <Box className="tq-table-header">
                            <Typography className="table-header-text">Negatives</Typography>
                          </Box>
                          <Box className="tq-table-body">
                            {que?.negatives?.length !== 0 ? <Box>{que?.negatives?.map((neg, index) => {
                              return (
                                <>
                                  <Box style={{ display: "flex" }}><img style={{ width: '15px', height: '15px', alignSelf: 'start', marginLeft: '10px' }} src={incompleteIcon} alt='incomplete' /><Typography className="table-body-title">{Object.keys(neg)}
                                    {Object.keys(neg).map((description, index) => {
                                      return (
                                        <>{
                                          neg[description].map((item) => {
                                            return (
                                              <>{item ?
                                                <Box>
                                                  <Typography className="table-body-description"> {`- ${item}`} </Typography>
                                                </Box> : <></>}
                                              </>)
                                          })
                                        }</>
                                      )
                                    })}
                                  </Typography></Box>

                                </>
                              )
                            })}</Box> : <Box style={{ display: "flex", color: "#8FAECF" }}>
                              <FiberManualRecordRoundedIcon className="dot-icon" style={{ color: "#8FAECF", marginTop: "6px" }} />
                              <Typography>None</Typography></Box>}
                          </Box>
                        </Box>
                      </Box>}
                  </Box>
                </Box>
              </Box>
            })
          }             
            </Box>
            </Box>
                        )
          })}

        </Box>
      </AccordionDetails>
    </Accordion>
   </>
   )
}


