import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "../../../atoms/Modal/Modal";
import ImageLoad from './ImageLoad';
import "./ReferenceImageModal.scss";
const ReferenceImageModal = ({imageReferenceModal, closeImageReference, downloadFile}) => {
    const body = () => {
        return (
            <Box className="shared-guide-image-reference-modal">
                <Box display='flex' alignItems='center' justifyContent='space-between' className='guide-image-nav'>
                <Box display='flex'>
                    <Typography className='image-label'>Image Reference</Typography>
                </Box>
                <CloseIcon onClick={closeImageReference} className='close-icon' sx={{ color: 'white' }}/>
                </Box>
                <ImageLoad data={imageReferenceModal?.data} url={imageReferenceModal?.data} downloadAPI={downloadFile} />
          </Box>
        )
    }
    return (
        <Modal
        open={imageReferenceModal?.status}
        onClose={closeImageReference}
        width="800px"
        height="600px"
        body={body()}
        />
    )
}

export default ReferenceImageModal;