import {useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CommentWrapper, QuestionWithoutTopic, QuestionWithTopic } from "../common";
import { GuidelineIntroductionType, StagesType, TechnicalType } from "../../Constants";
import ReferenceImageModal from "../ReferenceImageModal";
import SolutionModal from "../SolutionModal";

const TechnicalSection = ({
    guideData,
    topicList,
    technicalQuestionTopic,
    getTechnicalQuestions,
    downloadFile,
    isIndepthKnowledge,
    commentData,
    ...props
}) => {
    const [imageReferenceModal, setImageReferenceModal] = useState({
        status: false,
        data: null,
    });
    const [solutionModal, setSolutionModal] = useState({
        status: false,
        data: null,
    });
    const technicalData = guideData[StagesType.TECHNICAL]?.sections;

    const openImageReference = (data) => {
        setImageReferenceModal((prev)=>({...prev, status: true, data}))
    }

    const closeImageReference = () => {
        setImageReferenceModal((prev)=>({...prev, status: false, data: null}))
    }

    const openSolutionModal = (data) => {
        setSolutionModal((prev)=>({...prev, status: true, data}))
    }

    const closeSolutionModal = () => {
        setSolutionModal((prev)=>({...prev, status: false, data: null}))
    }

    const renderSectionDetail = () => {
        let easyCount = 0;
        let mediumCount = 0;
        let hardCount = 0;
        technicalData?.map(item => {
            if(item?.type === "TECHNICAL_QUESTIONS_DISTRIBUTION") {
                easyCount = item?.values.easy;
                mediumCount = item?.values?.medium;
                hardCount = item?.values?.hard;
            }
        });
        let totalCount = easyCount + mediumCount + hardCount;
        
        return (
            <Box className="head-detail-wrapper">
            <Typography className="common-text">Total number of questions to ask: <span className="bold-600">{totalCount}</span></Typography>
            {
                (technicalData?.[0]?.values?.easy > 0 || technicalData?.[0]?.values?.medium > 0 ||
                technicalData?.[0]?.values?.hard > 0) && (technicalData?.[0]?.values?.question_topic) &&
                Object.keys(technicalData?.[0]?.values?.question_topic)?.length > 0 && 
                (
                <Box className="topic-main-wrapper">
                    <Typography className="common-text">From the below topic list:</Typography>
                    {topicList()}
                </Box>
                )
            }
            <Box className="difficulty-wrapper">
                { easyCount ? (<Typography className="chip easy-chip">{easyCount} Easy</Typography>): ""}
                { mediumCount ? (<Typography className="chip medium-chip">{mediumCount} Medium</Typography>): ""}
                { hardCount ? (<Typography className="chip hard-chip">{hardCount} Hard</Typography>): ""} 
            </Box> 
        </Box>
        )
    } 

    const renderSectionBody = () => {
        let questionList = getTechnicalQuestions() || [];
        return (<Box className="question-box-main-container">
                <Box className="question-head-wrapper ">
                    <Typography className="head-text">Questions List</Typography>
                    <Typography className="head-sub-text">These are the questions suggested for you to ask.</Typography>
                </Box>
                <Box className="question-list-main-wrapper">
                    { 
                        technicalQuestionTopic?.length === 0 ? 
                            <QuestionWithoutTopic
                            type="TECHNICAL"
                            questionList={questionList}
                            openImageReference={openImageReference}
                            openSolutionModal={openSolutionModal}
                            />  : "" }
                    { 
                    technicalQuestionTopic?.length > 0 ? 
                        <QuestionWithTopic
                        type="TECHNICAL"
                        questionList={questionList}
                        questionTopicData={technicalQuestionTopic}
                        openImageReference={openImageReference}
                        openSolutionModal={openSolutionModal}
                         /> : ""}
                </Box>
        </Box>)
    }
    return (
        <Box className="common-section-main-wrapper">
             <CommentWrapper
            stageType={StagesType.TECHNICAL}
            sectionType={TechnicalType.TECHNICAL_QUESTIONS_DISTRIBUTION}
            sectionCommentData={commentData?.data?.[StagesType.TECHNICAL]?.[TechnicalType.TECHNICAL_QUESTIONS_DISTRIBUTION]}
            commentData={commentData}
            {...props}
            >
          {renderSectionDetail()}
          </CommentWrapper>
          <CommentWrapper
            stageType={StagesType.TECHNICAL}
            sectionType={TechnicalType.TECHNICAL_QUESTIONS}
            sectionCommentData={commentData?.data?.[StagesType.TECHNICAL]?.[TechnicalType.TECHNICAL_QUESTIONS]}
            commentData={commentData}
            {...props}
            >
          {renderSectionBody()}
          </CommentWrapper>
          <ReferenceImageModal
          imageReferenceModal={imageReferenceModal}
          closeImageReference={closeImageReference}
          downloadFile={downloadFile}
          />
           <SolutionModal
            section={"Technical Question"}
            solutionModal={solutionModal}
            closeSolutionModal={closeSolutionModal}
            isIndepthKnowledge={isIndepthKnowledge}
            />
        </Box>
    )
}
export default TechnicalSection;