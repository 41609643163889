import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import resumeIntroIcon from "../../../../../assets/images/resume-intro-icon.svg";
import { CommentWrapper, renderBulletPoint } from "../common";
import { GuidelineIntroductionType, StagesType } from "../../Constants";
const ResumeSection = ({
    guideData,
    commentData,
    ...props
}) => {
    const guideLinesData = guideData[StagesType.GUIDELINES_INTRODUCTION]?.sections;
    let resumeGuideData = [];
    guideLinesData?.map( item => {
        if(item?.type === "RESUME") {
            let arr1 = item?.default?.filter(item=>item?.selected) || [];
            let arr2 = item?.extras?.filter(item=>item?.selected) || [];
            resumeGuideData = [...arr1, ...arr2];
        }
    })
    const renderResume = () => {
        return (
            <Box className="resume-section-wrapper">
                <Box className="box-1">
                    <Typography className="common-head-text ">1. RESUME DISCUSSION</Typography>
                    <Box className="resume-intro-wrapper">
                        {
                            resumeGuideData && resumeGuideData?.map(item=> {
                                return renderBulletPoint("common-text", item?.label)
                            })
                        }
                    </Box>
                </Box>
                <Box className="box-2">
                    <img src={resumeIntroIcon}/>
                </Box>
        </Box>
        )
    }
    return (
        <Box className="resume-section-main-wrapper">
            <CommentWrapper
                stageType={StagesType.GUIDELINES_INTRODUCTION}
                sectionType={GuidelineIntroductionType.RESUME}
                sectionCommentData={commentData?.data?.[StagesType.GUIDELINES_INTRODUCTION]?.[GuidelineIntroductionType.RESUME]}
                commentData={commentData}
                {...props}
                >
                {renderResume()}
            </CommentWrapper>
        </Box>
    )
}
export default ResumeSection;