import GuidePreviewShared from "@iv/shared/components/pages/InterviewGuides/GuidePreview";

import { useEffect, useState } from "react"
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {imageFile} from "../../../services/file"

import SwtAlert from "../../../utilis/sweetalert/SwtAlert"
import { getAllGuideCommentsAPI,addCommentAPI,editCommentStatusAPI,addThreadAPI, deleteThreadAPI,getGuidePreviewDataAPI } from "../../../services/InterviewGuide";
import Box from "@material-ui/core/Box";
import {DashboardType} from "@iv/shared/constants/index";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const GuidePreview = () => {
    const [dataPreviewList, setDataPreviewList] = useState({});
    const [jobDescriptionLink, setJobDescriptionLink] = useState("");
    const history = useHistory();
    const state = useSelector((state) => state);
    const { search } = useLocation();
    const parsedQueryString = queryString.parse(search)
    const guide_id = parsedQueryString?.guide_id;
    const position_id = parsedQueryString?.position_id;
    const user = useSelector((state)=>state?.user);
    const urlToken = parsedQueryString?.token
    let sessionToken = sessionStorage.getItem("temporary_access_token");
    let headerToken = state?.auth?.access_token;
    const guideCommentData = {
        name:user?.name || "",
        email:user?.email || "",
        dashboardType:DashboardType.INTERVIEWER,
    }
    const guideCommentAPIS = {
        addCommentAPI,
        getAllGuideCommentsAPI,
        editCommentStatusAPI,
        addThreadAPI,
        deleteThreadAPI,
    }
    useEffect(() => {
        if(!headerToken)
        {
            if(!urlToken && sessionToken)
            {
                window.location.href = `${window.location.href}&token=${sessionToken}`;
                return;
            }
            else if(urlToken && !sessionToken)
            {
                history.push(`/guides/authorize?token=${urlToken}`);
                return;
            }
        }
        getGuidePreviewData();
    }, [])

    const getGuidePreviewData = async () => {
        if(!guide_id || guide_id?.length === 0 || guide_id === 'undefined')
        {
        SwtAlert("There is an error while fetching guide details since guide id is not present",2500,'error',false);
        return;
        }

        let params = {position_id: position_id};

        getGuidePreviewDataAPI(guide_id, params).then(res => {
            if (res?.status === 200) {
                setDataPreviewList(res?.data?.guide);
                setJobDescriptionLink(res?.data?.job_description);
            } else {
                SwtAlert(res?.data, 2000, "error", false);
            }
        }).catch(error => {
            SwtAlert(error.response, 2000, "error", false);
        });
    }
    return (
        <Box display='flex' padding={'20px'} justifyContent='center'>
            <GuidePreviewShared 
            dataPreview={dataPreviewList}
            guideCommentAPIS={guideCommentAPIS}
            guideCommentData={guideCommentData}
            jobDescriptionLink={jobDescriptionLink}
            downloadFile ={imageFile}
          />
        </Box>
    )

}

export default GuidePreview;